import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CreditCard, PaymentDto } from 'src/app/models/DTOs/payment-dto';
import { Address } from 'src/app/models/user';
import * as moment from 'moment';
import { SetProductDto } from 'src/app/models/DTOs/product-dto';
import { PaymentService } from 'src/app/services/payment.service';
import { PlanService } from 'src/app/services/plan.service';
import { CardService } from 'src/app/services/card.service';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { AdditionalExperiencesService } from 'src/app/services/additional-experiences.service';
import { StripeService } from 'src/app/services/stripe.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class CheckoutComponent implements OnInit {
  discount: number = 0;
  productList: any;
  pixCountdown: number = 300;
  orderList: any = {};
  selectedMethod: string | null = null;
  invalidCpf: boolean = false;
  generatedTicketBarcode: any = null;
  generatedTicketQRLink: any = null;
  generatedPixQRCode: any;
  generatedPixQRLink: any;
  selectedPlan: string = '';
  planName: string = '';
  loading = false;
  planInfo: any = {};
  creditCards: any = [];
  isCreditCardSelected: boolean = false;
  errorMessage: string = '';

  customerId: string = '';
  planId: any;
  subscriptionType: string = '';

  userInfo: any = {};
  customerInfo: any = {};
  userId: string = ''

  isAddingCard = false;
  isLoading = false;
  migrationType = '';
  visible = false;

  isMigrating = false;
  migrationSuccess = false;
  migrationMessage = 'Sucesso!';
  currentPlanName = '';

  payment: any = {
    address: new Address(),
    phoneNumber: '',
    creditCard: new CreditCard(),
    amount: 0,
    plan_id: '',
    payment_method: '',
    customer_id: 'cus_QJr7wKkuxu5wNglE',
  };

  isPlan: boolean = false;

  allExperiences: any;
  selectedExperience: any;
  isExperience: boolean = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private confirmationService: ConfirmationService,
    private planService: PlanService,
    private cardService: CardService,
    private authService: AuthService,
    private userService: UserService,
    private experiencesService: AdditionalExperiencesService,
    private stripeService: StripeService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.loading) {
          window.location.reload();
        }
      });

    this.userInfo = this.authService
      .getCurrentUserInfo()
      .subscribe((result: any) => {
        this.userId = result.data.id;
        this.stripeService
          .getCustomer(result.data.email)
          .subscribe((res: any) => {
            this.customerId = res.data[0].id;
            // if (this.isPlan) this.reloadCards();
          });
      });

    this.getProductListFromLocalStorage();
    this.route.queryParams.subscribe((params) => {
      this.getSelectedExperience(params['expId']);

      const planoId = params['planoId'];
      this.planId = params['selectedPlan'];

      const price = params['price'];
      this.currentPlanName = planoId;
      this.migrationType = params['migration'];
      this.subscriptionType = this.migrationType;

      if (
        this.migrationType === 'upgrade' ||
        this.migrationType === 'downgrade'
      )
        this.isMigrating = true;

      this.isPlan = !!params['planoId'];
      if (planoId) {
        this.payment.plan_id = planoId;
        this.loading = true;

        const p0 = new Promise((res, rej) => {
          this.authService.getCurrentUserInfo().subscribe((result: any) => {
            this.userInfo = result;
            this.cardService
              .getCardsByCustomerId(result?.data?.cpf)
              .subscribe((user_cards: any) => {
                this.creditCards = user_cards;
                res(true);
              });
          });
        });

        const p1 = new Promise((res, rej) => {
          this.planService.getPlanByName(planoId).subscribe((result: any) => {
            this.planInfo = result;
            res(true);
          });
        });

        Promise.all([p0, p1]).then(() => {
          this.loading = false;
        });
      }

      if (price) {
        this.payment.amount = parseFloat(price);
      }
    });
  }
  getSelectedExperience(expId: string) {
    if (expId !== undefined) {
      this.experiencesService
        .getAllExperiences()
        .subscribe((experiences: any) => {
          this.isExperience = true;
          this.allExperiences = experiences;
          this.selectedExperience = this.allExperiences.data.find(
            (exp: any) => exp.id === parseInt(expId)
          );

          this.payment = {
            amount: this.selectedExperience.valor,
            creditCard: this.creditCards,
            payment_method: this.selectedMethod,
            // customer_id: 'cus_QJr7wKkuxu5wNglE',
          };
        });
    }
  }

  cpfToOnlyDigits(cpf: any) {
    this.invalidCpf = false;
    let numbers = cpf.toString().replace(/\.|-/gm, '');
    if (numbers.length === 11) {
      return numbers;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'CPF inválido, verifique',
      });
      this.invalidCpf = true;
    }
  }

  generateOnClick() {
    if (!this.isPlan || this.isExperience) {
      if (this.selectedMethod) {
        if (this.selectedMethod === 'credit_card') {
          // this.reloadCards();
          return;
        }
        this.isCreditCardSelected = false;
      } else {
        this.loading = false;

        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Selecione um método de pagamento.',
        });
      }
      return;
    }
  }

  confirmPayment() {
    if (this.selectedMethod === null || this.selectedMethod === undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Selecione um método de pagamento.',
      });
      return;
    }

    this.loading = true;
    this.orderList.products = [];
    this.orderList.events = [];
    this.orderList.experiences = [];

    this.productList.forEach((product: any) => {
      if (product.itemType && product.itemType === 'event') {
        this.orderList.events.push({
          id: product.id,
          quantity: product.quantity ? product.quantity : 1,
        });
      } else {
        this.orderList.products.push({
          id: product.id,
          quantity: product.quantity ? product.quantity : 1,
        });
      }
    });

    if (this.isExperience) {

      console.log("EXPERIENCE!");


      this.orderList.experiences.push({
        id: this.selectedExperience.id,
        quantity: 1,
      });
    }

    const subscriptionPayment: any = {
      id: 'string',
      // customer_id: this.payment.customer_id,
      plan_id: this.payment.plan_id,
      card_id: this.payment.card_id,
      products: this.orderList.products,
      experiences: this.orderList.experiences,
      events: this.orderList.events,

      payments: [
        {
          payment_method: this.selectedMethod,
          credit_card: null,
          boleto: null,
          pix: null,
        },
      ],
    };

    if (this.selectedMethod === 'credit_card') {
      if (
        this.payment.creditCard.number === '' ||
        this.payment.creditCard.number === undefined
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Selecione um cartão de crédito.',
        });
        this.loading = false;
        return;
      }

      subscriptionPayment.payments[0].credit_card = {
        card_id: this.payment.creditCard.number,
      };
    }

    if (this.selectedMethod === 'boleto') {
      subscriptionPayment.payments[0].boleto = {
        instructions: 'Instrucoes do boleto',
      };
    }

    if (this.selectedMethod === 'pix') {
      subscriptionPayment.payments[0].pix = {
        expires_in: this.pixCountdown,
        // expires_at: moment().add(this.pixCountdown, 'seconds').toISOString(),
      };
    }

    this.paymentService.newOrder(subscriptionPayment).subscribe(
      (result: any) => {
        if (result.status === 'paid') {
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Pagamento realizado com sucesso!',
          });
          this.isCreditCardSelected = false;
        }
        if (result.status === 'pending') {
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Pagamento com pix',
          });
        }
        this.loading = false;
        localStorage.clear();
        setTimeout(() => {
          this.router.navigate(['/app']);
        }, 2200);
      },
      (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: error.error.mensagem ?? error.error,
        });
        console.log(error.error);
        this.loading = false;
      }
    );
  }

  getProductListFromLocalStorage() {
    this.productList = [];
    for (let index = 0; index < localStorage.length; index++) {
      try {
        var item = JSON.parse(localStorage.getItem(localStorage.key(index)!)!);
        if (
          localStorage.key(index)!.indexOf('cart_item_') > -1 ||
          localStorage.key(index)!.indexOf('cart_event') > -1
        ) {
          this.productList.push(item);
        }
      } catch (error) {
        console.error('Erro ao obter produtos do localStorage:', error);
      }
    }
  }

  getPriceByProduct(product: any) {
    return product.price.toFixed(2);
  }

  get subtotal() {
    let subtotal = this.payment.amount;
    this.productList.forEach((element: any) => {
      subtotal += element.value * (element.quantity ?? 1);
    });
    return subtotal;
  }

  get total() {
    let subtotal = this.subtotal;
    let discount = this.subtotal * this.discount;
    return subtotal - discount;
  }

  get isButtonDisabled() {
    return localStorage.length <= 0;
  }

  selectPlan(planName: string) {
    this.router.navigate(['/checkout'], { queryParams: { plan: planName } });
  }

  // cardAdded() {
  //   this.isAddingCard = false;
  //   this.reloadCards();
  // }

  // reloadCards() {
  //   this.loading = true;
  //   // this.cardService
  //   //   .getCardsByCustomerId(this.userInfo?.data?.cpf)
  //   //   .subscribe((user_cards: any) => {
  //   //     this.creditCards = user_cards;
  //   //     this.loading = false;
  //   //     this.isCreditCardSelected = true;
  //   //   });
  //   this.stripeService
  //     .getCardsAttached(this.customerId)
  //     .subscribe((user_cards) => {
  //       this.creditCards = user_cards.data;
  //       this.loading = false;
  //       this.isCreditCardSelected = true;
  //     });
  // }

  confirmPaymentPlan() {
    // if (
    //   this.payment.creditCard.number === '' ||
    //   this.payment.creditCard.number === undefined
    // ) {
    //   this.messageService.add({
    //     severity: 'error',
    //     summary: 'Erro',
    //     detail: 'Selecione um cartão de crédito.',
    //   });
    //   return;
    // }

    this.isLoading = true;
    const payload = {
      // plan_id: this.planInfo.id,
      plan_id: this.planInfo.plan_id,
      //
      // payment_method: 'credit_card',
      // card_id: this.payment.creditCard.number,
    };

    // console.log("PLAN", this.planInfo.plan_id);
    console.log('PAYLOAD: ', payload);

    if (this.migrationType === 'upgrade') {
      this.planService.upgradePlan(payload).subscribe((response) => {
        // console.log('Upgrading plan...', response);
        this.messageService.add({
          severity: 'success',
          summary: 'Pagamento confirmado!',
          detail: 'Plano atualizado com sucesso',
        });
        setTimeout(() => {
          this.router.navigate(['app/plan']);
        }, 2000);
      });
      return;
    }

    if (this.migrationType === 'downgrade') {
      this.planService.downgradePlan(payload).subscribe((response) => {
        // console.log('Downgrading plan...', response);
        this.messageService.add({
          severity: 'success',
          summary: 'Pagamento confirmado!',
          detail: 'Plano atualizado com sucesso',
        });
        // this.router.navigate(['app/plan']);

        setTimeout(() => {
          this.router.navigate(['app/plan']);
        }, 2000);
      });
      return;
    }

    this.paymentService.paySubscription(payload).subscribe(
      (result: any) => {
        console.dir(result, 'result');
      },
      (error) => {
        if (error.status === 422) {
          // this.errorMessage = 'Você já possui um plano ativo.';
          this.errorMessage = error;
          // console.log("ERROR", error.message);
        } else {
          this.errorMessage = 'Ocorreu um erro, tente novamente mais tarde.';
          this.isLoading = false;
        }
      }
    );
  }
  migratePlan() {
    this.isLoading = true;
    this.stripeService
      .migratePlan(this.planId, this.customerId, this.subscriptionType)
      .subscribe((res) => {
        // console.log('RES: ' + JSON.stringify(res));
        this.migrationSuccess = true;
        this.isLoading = false;
      });
  }
  onDialogHide() {
    this.loading = true;
    window.location.href = '/app/plan';
  }
}
