import { Component, Input, OnInit } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit {
  @Input() customer_id!: string;
  @Input() plan_id!: string;
  @Input() subscription_type!: string;
  @Input() user_id!: string;

  constructor(private authService: AuthService) {}
  async ngOnInit() {
    this.checkout(this.customer_id, this.plan_id, this.subscription_type, this.user_id);
  }

  async checkout(customerId: string, planId: string, subscriptionType: string, userId: string) {
    const stripe = await loadStripe(environment.stripePublishableKey);
    const token = this.authService.getToken();
    initialize(customerId, planId);

    async function initialize(
      customerId: string,
      productId: string,
      quantity: any = 1
    ) {
      async function fetchClientSecret() {
        let paymentMode = 'subscription';

        if (productId.includes('price')) {
          paymentMode = 'payment';
        }

        const response = await fetch(
          `${environment.apiUrl}create-checkout-session`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token
            },
            body: JSON.stringify({
              locale: 'pt-BR',
              productId: productId,
              quantity: quantity,
              customerId: customerId,
              paymentMode: paymentMode,
              subscriptionType: subscriptionType,
              userId: userId
            }),
          }
        );
        const { clientSecret } = await response.json();
        return clientSecret;
      }

      const checkout = await stripe!.initEmbeddedCheckout({
        fetchClientSecret,
      });

      checkout.mount('#checkout');
    }
  }
}
