<p-confirmDialog class="prime-confirmdialog" #cd [style]="{ width: '30vw' }">
  <ng-template pTemplate="header">
    <h3>Evento</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
       *ngIf="isTicketModalOpen"
      type="button"
      pButton
      label="Privado"
      icon="pi pi-users"
      (click)="cd.reject()"
    ></button>
    <button
      type="button"
      pButton
      icon="{{ isTicketModalOpen ? 'pi pi-ticket' : 'pi pi-check' }}"
      label="{{ acceptLabel }}"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>

<app-guest-picker-modal
   *ngIf="isGuestPickerModalOpen"
  [user]="null"
  (onGuestFound)="onGuestFound($event)"
  (modalClosed)="isGuestPickerModalOpen = false"
>
</app-guest-picker-modal>


<modal
  (modalClosed)="modalClosed1.emit()"
  [isModalOpen]="isModalOpen1"
  [maxWidth]="'90rem'"
>

 <p-progressSpinner  *ngIf="isLoading" [style]="{ width: '50px', height: '50px' }"  styleClass="w-4rem h-4rem" strokeWidth="8"></p-progressSpinner>
  <div  *ngIf="!isLoading" class="event-wrapper">
    <div class="upper-side">

      <draggable-input
        [filesVet]="event.url_thumbnail ? [event.url_thumbnail] : []"
        (onFilesUploaded)="getBannerPic($event)"
        [inputMessage]="'Escolha o banner do evento ou solte-a aqui.'"
        [isOnlyPictures]="true"
      >
    </draggable-input>

    </div>
    <div class="down-side">
      <div class="form row">
        <div class="form-item col-md-8">
          <label for="event-name">Título<small  *ngIf="!event.title && hasErrors"> *</small></label>
          <input
            class="input event-name"
            id="event-name"
            [disabled]="isReadOnly"
            type="text"
            placeholder="Digite o título da palestra..."
            [(ngModel)]="event.title"
          />
        </div>
        <div class="form-item col-md-4">
          <label for="product-type">Tipo <small  *ngIf="!event.type && hasErrors"> *</small></label>
          <p-dropdown
            [options]="eventTypes"
            [disabled]="isReadOnly"
            [(ngModel)]="event.type"
            placeholder="Selecione o tipo"
            [optionValue]="'value'"
            id="product-type"
            [optionLabel]="'title'"
          ></p-dropdown>
        </div>
      </div>
      <div class="form row">
        <div class="form-item col-md-4">
          <label for="event-date">Data <small  *ngIf="!event.initial_date && hasErrors"> *</small></label>
          <p-calendar
            dateFormat="dd/mm/yy"
            id="event-date"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            [showIcon]="true"
            inputId="icon"
            [(ngModel)]="event.initial_date"
          ></p-calendar>
        </div>
        <div class="form-item col-md-5">
          <label for="event-duration">Duração <small  *ngIf="!event.duration && hasErrors"> *</small></label>
          <input
            id="event-duration"
            type="text"
            value="{{ event.duration }} minutos"
            class="w-full input event-duration"
            disabled="true"
          />
          <p-slider
            [(ngModel)]="event.duration"
            [step]="30"
            [min]="30"
            [max]="480"
            class="w-full"
          ></p-slider>
        </div>
        <div class="form-item col-md-3">
          <label for="event-time">Horário<small  *ngIf="!event.initial_selected_hour && hasErrors"> *</small></label>
          <p-calendar
            dateFormat="dd/mm/yy"
            id="event-time"
            [showTime]="true"
            [showIcon]="true"
            [timeOnly]="true"
            hourFormat="24"
            [icon]="'pi pi-clock'"
            placeholder="00:00"
            [(ngModel)]="event.initial_selected_hour"
          >
          </p-calendar>
        </div>
      </div>
      <div class="form row textfield-row">
        <div class="form-item col-md-9">
          <label for="event-description">Descrição:<small *ngIf="!event.description && hasErrors"> *</small></label>
          <textarea name="" [(ngModel)]="event.description" id="event-description"></textarea>
          <p>
            {{ event.is_public ? 'Evento público' : 'Evento particular' }}
          </p>
          <div class="badges" *ngIf="!event.is_public">
            <p-avatarGroup styleClass="mb-3">
              <p-avatar
                *ngFor="let guest of invitedGuests"
                label="{{ guest.initials }}"
                size="xlarge"
                shape="circle"
                pTooltip="{{ guest.name }}"
                tooltipPosition="top"
              ></p-avatar>
              <button
                (click)="isGuestPickerModalOpen = true"
                pTooltip="{{ addTooltip }}"
                tooltipPosition="top"
                class="add-guest-btn"
              >
                +
              </button>
            </p-avatarGroup>
          </div>
        </div>
<!--
        <div class="form-item col-md-3">
          <label for="event-price">Valor do Ingresso:<small  *ngIf="!event.price && hasErrors"> *</small></label>
          <div class="form price row">
            <input
              id="event-price"
              [(ngModel)]="event.price"
              class="input"
              type="number"
            />
          </div>
        </div> -->
      </div>
      <div class="form-row button justify-content-between w-100 d-flex mt-2">
        <div class="d-flex justify-content-end w-100">
          <button
          *ngIf="formPopulated"
          (click)="isGuestPickerModalOpen = true"
          pTooltip="{{ addTooltip }}"
          tooltipPosition="top"
          class="add-guest-btn primary-button mr-8"
        >
          Convidar Usuários
        </button>
          <button class="btn btn-danger mr-2" (click)="modalClosed1.emit()">Cancelar Edição</button>
          <button (click)="formPopulated ? updateEvent() : saveEvent()" class="primary-button save-edit-event-btn">
            {{ formPopulated ? 'Atualizar' : 'Salvar' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</modal>
