// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://staging-api.agooravr.com/',
  // apiUrl: 'http://localhost:3000/',
  stripeApiUrl: 'https://api.stripe.com/v1/',
  paymentApiUrl: 'https://payments-api.agooravr.com/api/v1',
  // paymentApiUrl: 'http://localhost:8080/api/v1',
  stripePublishableKey: 'pk_test_51PxxWK09I2x9eXQFpXajiFHXAcQdtGPyLRKDf9JcKbCVTI9nq06E3QvVELxwxEGs54EIJxd0xB4qSqD6NxDJMKTW00vECKkXva',
  stripeSecretKey: 'sk_test_51PxxWK09I2x9eXQFSIW25z3w9dcA5TuXqEUWh5TiO6Itfb8vOewYe5d0I2WAA0Zx3hgdWXZXP8k3kD8NJdB6FggO006FjBKyID',
  gtk: '',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode becrcause it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
