<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"
      ><img src="assets/images/logo-filled.svg" height="40" class="mr-2"
    /></a>

    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarScroll"
      aria-controls="navbarScroll"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleMenuXs()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarScroll">
      <ul
        class="navbar-nav ml-auto my-2 my-lg-0 navbar-nav-scroll xs-hidden"
        style="--bs-scroll-height: 100px"
      >
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/app">Home</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">Download App</a>
        </li> -->
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarScrollingDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              [src]="profileImage"
              class="profile-picture"
              alt="profile picture"
            />
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarScrollingDropdown"
          >
            <li>
              <a class="dropdown-item" (click)="openMyProfile()">
                Perfil <svg-icon name="user-unfilled"></svg-icon>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="/app/cart">
                Carrinho <svg-icon name="cart-unfilled"></svg-icon
              ></a>
            </li>
            <li>
              <a class="dropdown-item" href="/media-admin/root">
                Mídias <svg-icon name="media-unfilled"></svg-icon
              ></a>
            </li>
            <li>
              <!-- <a class="dropdown-item" href="/hosts/experiences">
                Experiências <svg-icon name="media-unfilled"></svg-icon
              ></a> -->
            </li>
            <li>
              <a class="dropdown-item" href="/app/plan">
                Planos <svg-icon name="plans"></svg-icon
              ></a>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <a class="dropdown-item" (click)="logout()">
                Sair <svg-icon name="logoff-unfilled"></svg-icon
              ></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="mobile-menu xs-only" *ngIf="xsMenuOpen" @slideInLeft>
      <ul
    >
    <!-- TODO: Finish later
      <li>
      <div class="profile-info d-flex flex-column w-100 justify-content-center">
        <img
        [src]="profileImage"
        class="profile-picture"
        alt="profile picture"
      />
      </div>
    </li>
    -->
      <li>
        <a class="dropdown-item" (click)="openMyProfile()">
          Perfil <svg-icon name="user-unfilled"></svg-icon>
        </a>
      </li>
      <li>
        <a class="dropdown-item" href="/app/cart">
          Carrinho <svg-icon name="cart-unfilled"></svg-icon
        ></a>
      </li>
      <li>
        <a class="dropdown-item" href="/media-admin/root">
          Mídias <svg-icon name="media-unfilled"></svg-icon
        ></a>
      </li>
      <li>
        <a class="dropdown-item" href="/app/plan">
          Planos <svg-icon name="plans"></svg-icon
        ></a>
      </li>
      <!-- <li>
        <a class="dropdown-item" href="/hosts/experiences">
          Experiências <svg-icon name="media-unfilled"></svg-icon
        ></a>
      </li> -->
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li>
        <a class="dropdown-item" (click)="logout()">
          Sair <svg-icon name="logoff-unfilled"></svg-icon
        ></a>
      </li>
    </ul>
    </div>
  </div>
</nav>

<app-user-profile-modal
  [user]="user"
  [isEditing]="true"
  *ngIf="isUserModalOpen"
  (modalClosed)="isUserModalOpen = false"
>
</app-user-profile-modal>
