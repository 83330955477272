import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-use-terms',
  templateUrl: './use-terms.component.html',
  styleUrls: ['./use-terms.component.scss']
})
export class UseTermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
