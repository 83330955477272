import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Event } from 'src/app/models/event';
import { Address, Gender, User } from 'src/app/models/user';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-event-modal',
  templateUrl: './edit-event-modal.component.html',
  styleUrls: ['./edit-event-modal.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class EditEventModalComponent implements OnInit {
  @Output()
  modalClosed1: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  isReadOnly: boolean = false;
  @Input()
  event: Event = new Event();

  isPublicEvent: boolean = true;

  @Input()
  isModalOpen1: any;

  @Input()
  isGuestPickerModalOpen: boolean = false;

  @Output() eventUpdated: EventEmitter<Event> = new EventEmitter<Event>();

  formPopulated: boolean = false;

  eventDuration: any = 30;
  eventDate: any;
  eventTitle: any;
  eventiInitialDate: any;
  selectedEventType: any;
  isLoading: boolean = false;
  isTicketModalOpen: boolean = true;
  isFinalModalOpen: boolean = true;
  invitedGuests: any[] = [];
  hasErrors: boolean = false;
  acceptLabel = '';
  image64Backup: any;
  imageUrlBackup: any;
  eventTypes = [
    {
      title: 'Webinar',
      value: 'webinar',
    },
    {
      title: 'Meetup',
      value: 'meetup',
    },
    {
      title: 'Seminário',
      value: 'seminar',
    },
    {
      title: 'Aula/Curso',
      value: 'class',
    },
    {
      title: 'Meetups',
      value: 'meetups',
    },
    {
      title: 'Congresso',
      value: 'congress',
    },
    {
      title: 'Feiras de eventos', //master only
      value: 'fair',
    },
    {
      title: 'Outro',
      value: 'myEvent',
    },
  ];
  selectedHour: any;
  constructor(
    private userService: UserService,
    private eventService: EventService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  async ngOnInit() {

    this.event.price = 0 // Para deixar todos os eventos privados gratuitos

    if (this.event && this.event.title) {
      this.formPopulated = true;
      this.event.initial_date = new Date( this.event.initial_date as any )
      this.event.initial_selected_hour = new Date( this.event.initial_date as any )
      if (this.event.thumbnail_url) {
      this.event.thumbnail = await this.getBase64FromUrl(this.event.thumbnail_url)
    }
    }
    this.acceptLabel = 'Público';
    this.isTicketModalOpen = true;
    if (!this.formPopulated) {
      this.confirmationService.confirm({
        message: 'Selecione o tipo de evento que deseja criar:',
        header: 'Sucesso!',
        accept: () => {
          this.isPublicEvent = true;
          this.event.is_public = true;
        },
        reject: () => {
          this.isPublicEvent = false;
          this.event.is_public = false;
        },
      });
    }
  }

  getBannerPic(event: any) {
    if(event && event.length > 0){
      this.event.thumbnail = event[0];
      this.event.thumbnail_url = event[0].path;
      console.log(this.event.thumbnail, this.event.thumbnail_url, 'changed?')
    }
  }
  async getBase64FromUrl (url: any) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  updateEvent() {
    this.isLoading = true;
    this.eventService.updateEvent(this.event).subscribe(
      (updatedEvent: any) => {
        this.isLoading = false;
        this.eventUpdated.emit(updatedEvent);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Evento atualizado com sucesso!',
        });
      },
      (error) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Falha ao atualizar o evento. Por favor, tente novamente mais tarde.',
        });
      }
    );
    }
  saveEvent() {
    this.checkObligatoryFields();

    if(this.hasErrors){
      return
    }if(!this.hasErrors){
      this.isLoading = true;
      this.isTicketModalOpen = false;
      this.acceptLabel = 'Ok';
    if (this.invitedGuests.length > 0) {
      this.event.guests_users_id = this.invitedGuests.map((guest) =>
        guest.id.toString()
      );
    }

    this.event.is_public = this.isPublicEvent;
    console.dir('isPublicEvent:', this.isPublicEvent);
      this.eventService.addEvent(this.event).subscribe(
        (result: any) => {
          this.confirmationService.confirm({
            message: 'Evento criado com sucesso!',
            header: 'Sucesso!',
            accept: () => {
              this.modalClosed1.emit();
              window.location.reload();
            },
          });

        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: error.error.message ?? error.error,
          });
          this.isLoading = false;
        }
      );
    }
  }

  checkObligatoryFields(){
    console.log("Event Price: " + this.event.price);


    this.hasErrors = false;

    if(this.formPopulated && !this.event.is_public && this.event.guests_email.length < 1){
      this.hasErrors = true;
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Para eventos privados é necessário convidar ao menos uma pessoa',
      });
    }
    if(!this.formPopulated && !this.event.is_public && this.invitedGuests.length < 1){
      this.hasErrors = true;
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Para eventos privados é necessário convidar ao menos uma pessoa',
      });
    }

    if(!this.event.title || this.event.title === '' ||
      !this.event.initial_date ||
      !this.event.type ||
      !this.event.duration ||
      !this.event.initial_selected_hour ||
      !this.event.description){
      // !this.event.price){
        this.hasErrors = true;
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Preencha os campos obrigatórios!',
        });
        return
      }

  }

  get addTooltip() {
    return this.invitedGuests.length < 10
      ? 'Adicionar convidado'
      : 'Você pode convidar até 10 pessoas para um evento particular.';
  }

  onGuestFound(user: any) {
    this.addInvited(user);
    this.isGuestPickerModalOpen = false;
  }

  addInvited(user: any) {
    if (this.invitedGuests && this.invitedGuests.length < 10) {
      this.invitedGuests.push(user);
      console.log('invitedGuests', this.invitedGuests);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Você pode convidar até 10 pessoas para um evento particular.',
      });
    }
  }
}
