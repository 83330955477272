<!-- <form id="payment-form">
  <div id="card-element" class="pag">
    <div id="card"></div>
  </div>
  <div class="my-3">
    <div *ngIf="!isLoading">
      <button id="submit" class="primary-button px-2">Adicionar novo cartão</button>
      <button class="primary-button btn-danger ml-2" (click)="closeModal()">
      Cancelar
    </button>
    </div>

    <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="8" *ngIf="isLoading"></p-progressSpinner>
  </div>
  <div id="card-errors" role="alert"></div>
</form> -->


<div id="checkout"></div>
