import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  public getAllPlans() {
    const url = environment.paymentApiUrl + '/plans';
    return this.http.get(url);
  }

  public newOrder(payment: any) {
    const url = environment.paymentApiUrl + '/orders';
    return this.http.post(url, payment);
  }

  public paySubscription(payment: any) {
    const url = environment.paymentApiUrl + '/subscriptions';
    // const url = 'http://localhost:8080/api/v1' + '/subscriptions';
    return this.http.post(url, payment);
  }

  public paymentUnityCard(payment: any) {
    const url = environment.paymentApiUrl + '/subscriptions';
    return this.http.post(url, payment);
  }

  public getOrderStatus(orderId: string) {
    const url = environment.paymentApiUrl + '/orders/' + orderId;
    return this.http.get(url);
  }

  public getCustomerByDocument(document: string) {
    const url = environment.paymentApiUrl + '/customers/' + document;
    return this.http.get(url);
  }

  public getCustomerCards(document: string) {
    const url = environment.paymentApiUrl + '/customers/' + document + '/cards';
    return this.http.get(url);
  }

  // public cancelSubscription() {
  //   const url = environment.paymentApiUrl + '/subscriptions/cancel';
  //   return this.http.put(url, {});
  // }

  // public downgradeSubscription() {
  //   const url = environment.paymentApiUrl + '/subscriptions/downgrade';
  //   return this.http.put(url, {});
  // }

  // public upgradeSubscription() {
  //   const url = environment.paymentApiUrl + '/subscriptions/upgrade';
  //   return this.http.put(url, {});
  // }

  public updateSubscriptionCard(payment: any) {
    const url = environment.paymentApiUrl + '/subscriptions/credit-card';
    return this.http.put(url, payment);
  }

  public renewSubscription() {
    const url = environment.paymentApiUrl + '/subscriptions/active';
    return this.http.put(url, {});
  }

  public updateSubscriptionCreditCard(card_id: any) {
    const url =  environment.paymentApiUrl + '/subscriptions/credit-card';
    return this.http.put(url, {
      card_id
    });
  }
}
