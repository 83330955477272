import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isStripeRequest = request.url.includes('https://api.stripe.com/v1/');

    // console.log(' this.authService.getToken: ', this.authService.getToken());

    if (
      !isStripeRequest &&
      // request.url.indexOf('viacep') < 0 &&
      request.url.indexOf('forgot') < 0 &&
      request.url.indexOf('email-confirmation') < 0 &&
      request.url.indexOf('set-pwd') < 0 &&
      request.url.indexOf('login') < 0 &&
      request.url.indexOf('plans') < 0 &&
      request.url.indexOf('amazonaws.com') < 0 &&
      this.authService.getToken() != 'undefined'
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`,
        },
      });
    }

    return next.handle(request);
  }
}
