<p-toast></p-toast>

<!-- <app-card-modal *ngIf="isAddingCard" (modalClosed)="isAddingCard = false" (cardAdded)="cardAdded()" (loadCardsEvent)="reloadCards()"></app-card-modal> -->

<p-dialog [(visible)]="visible" (onHide)="onDialogHide()">
  <app-stripe *ngIf="visible" [customer_id]="customerId" [plan_id]="planId" [subscription_type]="subscriptionType" [user_id]="userId"></app-stripe>
</p-dialog>

<div class="checkout-wrapper" *ngIf="!loading">
  <div class="left-side">
    <div class="checkout-header">
      <h2 class="checkout-title" *ngIf="!isPlan">Pagamento</h2>
      <h2 class="checkout-title" *ngIf="isPlan">Contratar Plano</h2>
  </div>

      <!-- Purchasing a regular order -->
    <div class="payment-choices w-100 mt-4" *ngIf="!isPlan">
      <div class="payment-choice d-flex justify-content-between w-100" *ngIf="!isCreditCardSelected">
        <div class="left d-flex align-items-center">
          <img class="credit-card" src="/assets/images/logos/credit-card.svg">
          <span>Cartão de Crédito</span>
        </div>

        <div class="right d-flex">
          <p-radioButton name="groupname" value="credit_card" [(ngModel)]="selectedMethod" (click)="generateOnClick()"></p-radioButton>
        </div>
      </div>


    <div class="payment-choices w-100 mt-4 mb-2" *ngIf="isCreditCardSelected">
      <div class="payment-choice d-flex justify-content-between w-100">
        <div class="left d-flex align-items-center">
          <img class="credit-card" src="/assets/images/logos/credit-card.svg">
          <span>Cartão de Crédito</span>
        </div>
      </div>
      <div class="payment-details">
        <div class="payment-choice d-flex justify-content-between w-100 credit-card-list" *ngFor="let card of creditCards">
          <div class="left d-flex align-items-center">
            <img class="credit-card" src="/assets/images/logos/credit-card.svg">
            <span>xxxx xxxx xxxx {{card.card.last4}}</span>
          </div>
          <div class="right d-flex">
            <p-radioButton name="groupname" [value]="card.id" [(ngModel)]="payment.creditCard.number"></p-radioButton>
          </div>
        </div>
          <button class="primary-button px-2 my-2" (click)="isAddingCard = true">Adicionar novo cartão</button>
        <div class="no-cards d-flex flex-column" *ngIf="creditCards.length == 0">
          <span class="m-4 justify-content-center w-100 d-flex">
            Nenhum cartão de crédito adicionado
          </span>
        </div>
      </div>
    </div>

      <div class="payment-choice d-flex justify-content-between w-100" *ngIf="!isPlan">
        <div class="left d-flex align-items-center">
          <img src="/assets/images/logos/pix.png" class="pix">
          <span>Pix</span>
        </div>
        <div class="right d-flex">
          <p-radioButton name="groupname" value="pix" [(ngModel)]="selectedMethod" (click)="generateOnClick()"></p-radioButton>
        </div>
      </div>

      <div class="payment-choice d-flex justify-content-between w-100" *ngIf="!isPlan">
        <div class="left d-flex align-items-center">
          <img src="/assets/images/logos/boleto-logo.png">
          <span>Boleto Bancário</span>
        </div>
        <div class="right d-flex">
          <p-radioButton name="groupname" value="boleto" [(ngModel)]="selectedMethod" (click)="generateOnClick()"></p-radioButton>
        </div>
      </div>
    </div>

    <!-- Purchasing a plan -->
     <!-- <div class="payment-choices w-100 mt-4 mb-3" *ngIf="isPlan">
      <div class="payment-choice d-flex justify-content-between w-100">
        <div class="left d-flex align-items-center">
          <img class="credit-card" src="/assets/images/logos/credit-card.svg">
          <span>Cartão de Crédito</span>
        </div>
      </div>
      <div class="payment-details">
        <div class="payment-choice d-flex justify-content-between w-100 credit-card-list" *ngFor="let card of creditCards">
          <div class="left d-flex align-items-center">
            <img class="credit-card" src="/assets/images/logos/credit-card.svg">
            <span>xxxx xxxx xxxx {{card.card.last4}}</span>
          </div>
          <div class="right d-flex">
            <p-radioButton name="groupname" [value]="card.id" [(ngModel)]="payment.creditCard.number"></p-radioButton>
          </div>
        </div> -->
        <!-- <button class="primary-button px-2 my-2" (click)="isAddingCard = true">Adicionar novo cartão</button> -->


        <!-- <button class="primary-button px-2 my-2" (click)="visible = true" *ngIf="!isMigrating">Contratar Plano</button> -->
        <div *ngIf="isMigrating">
            <!-- <button class="primary-button px-2 my-2" (click)="migratePlan()">Migrar de Plano</button> -->
            <p-dialog [(visible)]="migrationSuccess" [header]="migrationMessage" (onHide)="onDialogHide()">
              <p>Plano migrado com sucesso!</p>
              <p style="color: brown; font-weight: 600;">Plano atual: {{ currentPlanName }}</p>
              <div style="display: flex; justify-content: center;">
                <button class="primary-button" (click)="migrationSuccess = false">OK</button>

              </div>
            </p-dialog>

        </div>
        <!-- <div class="no-cards d-flex flex-column" *ngIf="creditCards.length == 0"> -->
          <!-- <span class="m-4 justify-content-center w-100 d-flex"> -->
            <!-- Nenhum cartão de crédito adicionado -->
          <!-- </span> -->
        <!-- </div> -->

      <!-- </div> -->
      <!-- </div> -->
    <div class="checkout-body container">
      <div class="card-payment form" *ngIf="selectedMethod == 'card'">
        <h2>Cartão de Crédito</h2>
        <div class="row">
          <div class="card-number form-item col-md-12">
            <label for="ccn">Número do Cartão:</label>
            <input id="ccn" class="input" type="tel" inputmode="numeric" pattern="[0-9\s]{13,19}"
              autocomplete="cc-number" maxlength="19" placeholder="xxxx xxxx xxxx xxxx"
              [(ngModel)]="payment.creditCard.number" />
          </div>
        </div>
        <div class="row">
          <div class="form-item col-md-5">
            <label for="cpf">CPF do Titular:</label>
            <input id="cpf" class="input" placeholder="Digite o CPF do titular" type="text"
              [(ngModel)]="payment.holderCpf" pInputText />
          </div>

          <div class="form-item col-md-7">
            <label for="name">Nome do Titular:</label>
            <input id="name" class="input" placeholder="Digite o nome do titular" type="text"
              [(ngModel)]="payment.holderName" pInputText />
          </div>
        </div>
        <div class="row">
          <div class="card-number form-item col-md-7">
            <label for="conf">Dígitos de Confirmação:</label>
            <input id="conf" class="input" inputmode="numeric" autocomplete="conf-number" maxlength="3"
              placeholder="xxx" [(ngModel)]="payment.creditCard.confirmationDigits" />
          </div>
          <div class="form-item col-md-2">
            <label class="block">Mês de Vencimento:</label>
            <input type="number" class="input" [(ngModel)]="payment.creditCard.dueMonth" />
          </div>
          <div class="form-item col-md-2">
            <label class="block">Ano de Vencimento:</label>
            <input type="number" class="input" [(ngModel)]="payment.creditCard.dueYear" />
          </div>
        </div>
      </div>
      <div class="pix-payment" *ngIf="selectedMethod == 'pix' && generatedPixQRLink">
        <h2>Pix</h2>
        <div class="countdown">
          <h4 class="label">Seu código expira em:
            <countdown #cd [config]="{leftTime: pixCountdown}"></countdown>
          </h4>
          <h4 class="label" *ngIf="pixCountdown == 0">Seu código Pix expirou.</h4>
        </div>
        <img *ngIf="pixCountdown != 0" src="{{generatedPixQRLink}}" alt="QR Code Pix">
      </div>
      <div class="bill-payment" *ngIf="selectedMethod == 'boleto' && generatedTicketBarcode && generatedTicketQRLink">
        <h2>Boleto Bancário</h2>
        <p-tabView class="main-panel">
          <p-tabPanel header="Código de Barras">
            <img src="{{ generatedTicketBarcode }}" alt="Código de Barras do Boleto">
          </p-tabPanel>
          <p-tabPanel header="QR Code">
            <img src="{{ generatedTicketQRLink }}" alt="QR Code do Boleto">
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>

  <div class="right-side container" *ngIf="!isPlan && !isExperience">
    <div class="row">
      <div class="title col-md-6">Subtotal</div>
      <div class="value subtotal col-md-6" *ngIf="payment?.amount>0 " >{{payment.amount| currency:'BRL'}}</div>
      <div class="value subtotal col-md-6" *ngIf="!payment?.amount " >{{0| currency:'BRL'}}</div>
    </div>
    <div class="row">
      <div class="title col-md-6">Descontos</div>
      <div class="value discount col-md-6"*ngIf="discount>0 ">{{ discount| currency:'BRL' }}</div>
      <div class="value discount col-md-6"*ngIf="!discount ">{{0| currency:'BRL' }}</div>
    </div>
    <div class="row">
      <div class="title col-md-6">Total</div>
      <div class="value col-md-6"*ngIf="total>0 ">{{total| currency:'BRL'}}</div>
      <div class="value col-md-6"*ngIf="!total ">{{0| currency:'BRL'}}</div>
    </div>

    <button [class.disabled]="isButtonDisabled" class="primary-button col-md-12" (click)="confirmPayment()">
      Confirmar Pagamento
    </button>
  </div>


  <div class="right-side container" *ngIf="isExperience">
    <div class="row">
      <div style="font-weight: 600; color: brown !important;">{{ selectedExperience.nome }}</div>
      <div class="title col-md-6">Subtotal</div>
      <div class="value subtotal col-md-6">{{ selectedExperience.valor / 100 | currency: 'BRL' : 'symbol' }}</div>
    </div>
    <div class="row">
      <div class="title col-md-6">Descontos</div>
      <div class="value discount col-md-6">{{ discount | currency: 'BRL' : 'symbol' }}</div>
    </div>
    <div class="row">
      <div class="title col-md-6">Total</div>
      <div class="value col-md-6">{{ total / 100 | currency: 'BRL' : 'symbol' }}</div>
    </div>

    <button  class="primary-button col-md-12" (click)="confirmPayment()">
      Confirmar Pagamento
    </button>
  </div>


    <div class="right-side container plan" *ngIf="isPlan">
    <div class="row">
      <div class="title col-md-6">Detalhes do plano</div>
    </div>
    <span style="color: brown; font-weight: 600;" class="my-2">Plano {{planInfo.name}}</span>
    <div class="row">
      <div class="title col-md-6">Total</div>
      <div class="value discount col-md-6">{{ planInfo.price / 100  | currency: 'BRL' : 'symbol' }}</div>
    </div>
    <!-- <div class="row"> -->
        <!-- <button [class.disabled]="!payment?.creditCard?.number" class="primary-button col-md-12 my-2" (click)="confirmPaymentPlan()" *ngIf="!isLoading"> -->
        <!-- <button  class="primary-button col-md-12 my-2" (click)="confirmPaymentPlan()" *ngIf="!isLoading"> -->
        <button  class="primary-button col-md-12 my-2" (click)="visible = true && isLoading = true" *ngIf="!isLoading && !isMigrating">
        Confirmar Pagamento
      </button>
        <button  class="primary-button col-md-12 my-2" (click)="migratePlan()" *ngIf="!isLoading && isMigrating">
        Confirmar Migração
      </button>
      <div class="flex justify-content-center my-4" *ngIf="isLoading && isMigrating">
        <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="8"></p-progressSpinner>
      </div>
      <span class="message-error my-3" *ngIf="errorMessage">{{errorMessage}}</span>
    <!-- </div> -->
  </div>

</div>

<div class="checkout-wrapper d-flex justify-content-center align-items-center" *ngIf="loading">
  <p-progressSpinner></p-progressSpinner>
</div>
